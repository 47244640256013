<section id="about_Component">
  <div class="firstSector">
    <div class="justText">
      <h1 class="fontSyne disableTextSelection">About me</h1>

      <!-- Variante block -->
      <p class="fontOverpass disableTextSelection">
        Hello, I am a German-speaking frontend developer living in Loma Plata,
        Paraguay. Driven by the endless possibilities in the IT world, I am
        passionate about creating visually appealing and user-friendly websites
        and applications.
      </p>
      <br />

      <p class="fontOverpass disableTextSelection">
        I can work effectively remote from Paraguay and have the advantage of
        being available for work in the German-speaking region even during late
        night hours, thanks to the different time zones.
      </p>

      <!-- Variante inline -->
      <!-- <span class="fontOverpass disableTextSelection">
        Hello, I am a German-speaking frontend developer living in Loma Plata,
        Paraguay. Driven by the endless possibilities in the IT world, I am
        passionate about creating visually appealing and user-friendly websites
        and applications.
      </span>
      <br />

      <span class="fontOverpass disableTextSelection">
        I can work effectively remote from Paraguay and have the advantage of
        being available for work in the German-speaking region even during late
        night hours, thanks to the different time zones.
      </span> -->

      <button class="fontOverpass disableTextSelection">Let's talk</button>
    </div>

    <img
      class="disableTextSelection"
      src="assets/images/AksenovKonstantinBewerbungsfoto.avif"
      alt="Portrait of developer."
    />
  </div>

  <div class="secondSector">
    <a href="#skills_Component"> <div class="arrowLeft"></div></a>
  </div>
</section>
