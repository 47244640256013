<section id="skills_Component">
  <div class="firstSector">
    <h1 class="fontSyne disableTextSelection">My skills</h1>

    <div class="labels">
      <div class="skill_item">
        <div class="icon angular"></div>
        <span class="fontOverpass disableTextSelection">Angular</span>
      </div>

      <div class="skill_item">
        <div class="icon materialDisign"></div>
        <span class="fontOverpass disableTextSelection">Material Design</span>
      </div>

      <div class="skill_item">
        <div class="icon firebase"></div>
        <span class="fontOverpass disableTextSelection">Firebase</span>
      </div>

      <div class="skill_item">
        <div class="icon typeScript"></div>
        <span class="fontOverpass disableTextSelection">TypeScript</span>
      </div>

      <div class="skill_item">
        <div class="icon javaScript"></div>
        <span class="fontOverpass disableTextSelection">JavaScript</span>
      </div>

      <div class="skill_item">
        <div class="icon html"></div>
        <span class="fontOverpass disableTextSelection">HTML</span>
      </div>

      <div class="skill_item">
        <div class="icon css"></div>
        <span class="fontOverpass disableTextSelection">CSS</span>
      </div>

      <div class="skill_item">
        <div class="icon git"></div>
        <span class="fontOverpass disableTextSelection">Git</span>
      </div>

      <div class="skill_item">
        <div class="icon Scrum"></div>
        <span class="fontOverpass disableTextSelection">Scrum</span>
      </div>

      <div class="skill_item">
        <div class="icon rest"></div>
        <span class="fontOverpass disableTextSelection">Rest-Api</span>
      </div>

      <div class="skill_item">
        <div class="icon wordPress"></div>
        <span class="fontOverpass disableTextSelection">WordPress</span>
      </div>
    </div>
  </div>

  <div class="secondSector">
    <a href="#portfolio_Component"> <div class="arrowRight"></div></a>
  </div>
</section>
