<section>
  <div class="landingPage_firstSector">
    <div class="landingPage_Headline_Box">
      <div class="landingPage_Headline_Titel">
        <h1 class="fontSyne disableTextSelection">
          Frontend <br />
          Developer
        </h1>
      </div>
    </div>

    <div class="landingPage_Headline_NameBox">
      <div class="landingPage_Headline_SolidLine"></div>
      <h3 class="fontOverpass disableTextSelection">Konstantin Aksenov</h3>
    </div>
  </div>

  <div class="landingPage_secondSector">
    <button class="fontOverpass disableTextSelection">Send a message</button>
  </div>

  <div class="landingPage_thirdSector">
    <a class="fontOverpass disableTextSelection" href=""
      >Konstantin-Aksenov&#64;dev2k.net</a
    >
    <span class="fontOverpass disableTextSelection">
      <a href="#about_Component">
        Scroll <br />
        Down</a
      >
    </span>
  </div>
</section>

<app-about></app-about>
<app-skills></app-skills>
<app-portfolio></app-portfolio>
<!-- <app-comments></app-comments> -->
<!-- <app-contact></app-contact> -->
