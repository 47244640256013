<section id="portfolio_Component">
  <h1 class="fontSyne disableTextSelection">Portfolio</h1>

  <span class="fontOverpass disableTextSelection"
    >Ich habe Erfahrung im Aufbau von Projekten mit unterschiedlichen Frontend -
    Technologien und Konzepten.</span
  >

  <app-project></app-project>
  <!-- <app-project01></app-project01> -->
  <!-- <app-project02></app-project02> -->
</section>
